// FUNÇÕES/MÓDULOS SITE
import initCarousel from '../modules/initCarousel';

initCarousel();
///////////////////////////////////
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";

///////////////////////////////////
const bodyElement = document.querySelector('body')
function openCloseModalVideoGoals() {
    const buttonOpen = document.querySelector('.mandala .playBtn')
    const buttonClose = document.querySelector('#modal-video_goals button')

    try {
        buttonOpen.addEventListener('click', openCloseModal)
        buttonClose.addEventListener('click', openCloseModal)
        function openCloseModal() {
            const modal = document.getElementById('modal-video_goals')
            modal.classList.toggle("active")
            bodyElement.classList.toggle("active")

            $('#home-video').each(function () {
                this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
            });
        }
    } catch (error) {

    }
}

openCloseModalVideoGoals()
///////////////////////////////////

var swiper = new Swiper(".mySwiper", {
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
    },
});

function reateSwiperSlider() {
    var swiper2 = new Swiper(".swiper2", {
        slidesPerView: 'auto',
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    ////////////////////////////////////////////////////
    var swiper3 = new Swiper(".swiper-midia", {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 20,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        loop: true,
        dragable: false,
    });
}

////////////////////////START FUNC////////////////////////////
const swiperContent = document.querySelectorAll('.content_text_container .content_text')
swiperContent[0].classList.add('active')
window.onload = () => {
    const heightSwiperContent = swiperContent[0].clientHeight
    gsap.to('.content_text_container', {
        duration: 0.7,
        height: `${heightSwiperContent}px`
    })
    if (window.screen.width < 999) {
        const heightImage = swiperImage[0].clientHeight
        gsap.to('.content_image_container', {
            duration: 0.7,
            height: `${heightImage}px`
        })
    }

    //Create Swiper Slider
    reateSwiperSlider()
}

const swiperImage = document.querySelectorAll(".content_image_container img")
swiperImage[0].classList.add('active')

const paginationCard = document.querySelectorAll(".pagination-cards")
paginationCard[0].classList.add('active')

paginationCard.forEach(pagCard => {
    paginationCard[0].classList.add('active')
    pagCard.addEventListener('click', () => {
        paginationAction(pagCard)
    })
})
function paginationAction(pagClicked) {
    let clickedIdentify = Number(pagClicked.dataset.identify)
    let currentSwiper = 0;

    swiperContent.forEach(swipeContent => {
        if (swipeContent.classList.contains("active")) {
            currentSwiper = Number(swipeContent.dataset.identify)
            console.log(currentSwiper)
        }
    })
    let numClicked = currentSwiper - clickedIdentify
    actionClickedButton(numClicked)
}
function actionClickedButton(diferencePagination) {

    if (diferencePagination < 0) {
        let num = diferencePagination * -1
        for (let i = 0; i < num; i++) {

            buttonNext.click()
        }
    } else if (diferencePagination > 0) {
        let num = diferencePagination
        for (let i = 0; i < num; i++) {
            buttonPrev.click()
        }
    }
}
function changeSliderNext() {
    const maxSlider = swiperContent.length - 1
    let activedSlider = ''
    swiperContent.forEach((element, index) => {
        if (element.classList.contains('active')) {
            activedSlider = index
            const heightSlider = swiperContent[activedSlider].clientHeight
            gsap.to('.content_text_container', {
                duration: 0.7,
                height: `${heightSlider}px`
            })

            if (window.screen.width < 999) {
                const heightImage = swiperImage[activedSlider].clientHeight
                gsap.to('.content_image_container', {
                    duration: 0.7,
                    height: `${heightImage}px`
                })
            }
        }
    })

    if (maxSlider == activedSlider) {

    } else {
        swiperContent[activedSlider].classList.remove('active')
        swiperContent[activedSlider + 1].classList.add('active')
        swiperImage[activedSlider].classList.remove('active')
        swiperImage[activedSlider + 1].classList.add('active')
        paginationCard[activedSlider].classList.remove('active')
        paginationCard[activedSlider + 1].classList.add('active')
    }
}
function changeSliderPrev() {
    const maxSlider = paginationCard.length - 1
    let activedSlider = ''
    swiperContent.forEach((element, index) => {
        if (element.classList.contains('active')) {
            activedSlider = index
            const heightSlider = swiperContent[activedSlider].clientHeight
            gsap.to('.content_text_container', {
                duration: 0.7,
                height: `${heightSlider}px`
            })
            if (window.screen.width < 999) {
                const heightImage = swiperImage[activedSlider].clientHeight
                gsap.to('.content_image_container', {
                    duration: 0.7,
                    height: `${heightImage}px`
                })
            }
        }
    })

    console.log(activedSlider)
    if (activedSlider == 0) {
    } else {
        swiperContent[activedSlider].classList.remove('active')
        swiperContent[activedSlider - 1].classList.add('active')
        swiperImage[activedSlider].classList.remove('active')
        swiperImage[activedSlider - 1].classList.add('active')
        paginationCard[activedSlider].classList.remove('active')
        paginationCard[activedSlider - 1].classList.add('active')
    }
}
const buttonNext = document.querySelector(".change-slider-next")
buttonNext.addEventListener('click', changeSliderNext)
const buttonPrev = document.querySelector(".change-slider-prev")
buttonPrev.addEventListener('click', changeSliderPrev)
////////////////////////END FUNC////////////////////////////////
// function buttonClickActiveBackground (){
//   let activeSlider
//   swiperContent.forEach(swiper => {
//     if(swiper.classList.contains('swiper-slide-active')){
//       activeSlider = swiper.dataset.identify
//     }
//   })
//   paginationCard.forEach(pagination => {
//     pagination.classList.remove('active')
//     if(pagination.dataset.identify == activeSlider){
//       pagination.classList.add("remove")
//     }
//   })
// }
// buttonNext.addEventListener('click', buttonClickActiveBackground)
// buttonPrev.addEventListener('click', buttonClickActiveBackground)
//////////////////////INITIAL BANNER/////////////
const bannerInitialText = document.querySelectorAll("#initial-banner .text")
const bannerInitialImage = document.querySelectorAll("#initial-banner .image")
gsap.from(bannerInitialText, {
    x: -50,
    opacity: 0,
    duration: 1,
    delay: 1,
})
gsap.from(bannerInitialImage, {
    x: 50,
    opacity: 0,
    duration: 1,
    delay: 1,
})
gsap.from('.home__sliderBtn', {
    x: -50,
    opacity: 0,
    duration: 1,
    delay: 1,
})
let stringWithoutNums = ''
const numCards = document.querySelectorAll(".animation-nums")
numCards.forEach(e => {
    const contentElement = e.innerHTML
    const numsWithoutString = contentElement.replace(/[^0-9]/g, '')
    stringWithoutNums = String(contentElement.replace(numsWithoutString, ''))
    e.innerHTML = stringWithoutNums + ' 0'
})
let verify = 0
function setCrescentNumber() {
    if (verify == 0) {

        numCards.forEach(e => {

            const numsWithoutString = e.dataset.num
            const num = Number(numsWithoutString)
            let count = parseInt((num * 1) / 35) //Regra de 3 básica
            let contentNum = 0
            setInterval(() => {
                if (contentNum <= num) {
                    e.innerHTML = `${stringWithoutNums} ${contentNum}`
                    contentNum += count
                } else {
                    e.innerHTML = `${stringWithoutNums} ${num}`
                }
            }, 20)
        })
    }
    verify = 1
}
gsap.to('.result', {
    scrollTrigger: {
        trigger: '.result',
        start: "top 85%",
        toggleActions: 'play none none none',
        onEnter: setCrescentNumber,
    },
})

// Motivos
const liElement = document.querySelectorAll(".motivos li")
const liLength = liElement.length - 1
for (let i = 0; i <= liLength; i++) {
    liElement[i].style.transitionDelay = `${(i + 1) * 0.15}s`
}

///////////////////////WHAT IS ////////////////
// const whatIsImg = document.querySelector('.whatIs .boxImgs')
// const whatIsText = document.querySelector('.whatIs .whatIs__box')
// gsap.from(whatIsImg, {
//     scrollTrigger: {
//         trigger: '.whatIs',
//         start: "top 85%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: -50,
//     duration: 0.7,

// })

// gsap.from(whatIsText, {
//     scrollTrigger: {
//         trigger: '.whatIs',
//         start: "top 85%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: 50,
//     duration: 0.7,

// })

// /////////////////////// FUNCIONALIDADES ////////////////
// const containerFunc = document.querySelector('#func__content')
// gsap.from(containerFunc, {
//     scrollTrigger: {
//         trigger: '.funcionalidades',
//         start: "top 75%",
//         toggleActions: 'play none none none',
//         onEnter: () => {
//             console.log('h1')
//         },
//     },
//     opacity: 0,
//     duration: 1,
// })

// /////////////////////// MIDIA ////////////////
// const containerMidia = document.querySelector('.section-midia .swiper4')
// gsap.from(containerMidia, {
//     scrollTrigger: {
//         trigger: '.section-midia',
//         start: "top 75%",
//         toggleActions: 'play none none none',
//         onEnter: () => {
//             console.log('h1')
//         },
//     },
//     opacity: 0,
//     duration: 1,
//     y: 50,
// })

/////////////////////DEPO////////////////////////
// const sectionDepoCards = document.querySelectorAll('.depo .swiper2')
// gsap.from(sectionDepoCards, {
//     scrollTrigger: {
//         trigger: '.depo',
//         start: "top 75%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: 100,
//     duration: 0.7,
// })

//////////////////////BLOG///////////////////////
// const sectionBlogCards = document.querySelectorAll('.section-blog .content-card')
// gsap.to(sectionBlogCards, {
//     scrollTrigger: {
//         trigger: sectionBlogCards[0],
//         start: "top 95%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 1,
//     y: 0,
//     duration: 0.7,
//     stagger: 0.2,
//     ease: "none"
// })

////////////////////MOTIVOS////////////////////


// /////////////////////MANDALA////////////////////
// const mandalaImage = document.querySelector('.mandala .mandala__score')
// const mandalaText = document.querySelector('.mandala .mandala__outBox')
// gsap.from(mandalaImage, {
//     scrollTrigger: {
//         trigger: '.mandala',
//         start: "top 85%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: -50,
//     duration: 0.7,

// })

// gsap.from(mandalaText, {
//     scrollTrigger: {
//         trigger: '.mandala',
//         start: "top 85%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: 50,
//     duration: 0.7,

// })

// /////////////////////KnowCompa///////////////////
// const companyCards = document.querySelectorAll('.knowComp .parent img')
// gsap.from(companyCards, {
//     scrollTrigger: {
//         trigger: '.knowComp',
//         start: "top 80%",
//         toggleActions: 'play none none none',
//     },
//     opacity: 0,
//     y: 100,
//     duration: 0.7,
//     stager: 0.2
// })

////////////////////////////////MANDALA ADJUSTMENT//////////////////////
const mandalaImageAdjusment = document.querySelectorAll('.container-svg img')
if (mandalaImageAdjusment) {
    mandalaImageAdjusment.forEach(e => {
        e.addEventListener('mouseover', () => {
            e.classList.add('active')
        })
    })
    mandalaImageAdjusment.forEach(e => {
        e.addEventListener('mouseout', () => {
            e.classList.remove('active')
        })
    })
    const containerMandala = document.querySelector(".container-svg")
    const containerMandalaWidth = containerMandala.clientWidth
    containerMandala.style.height = containerMandalaWidth + 'px'
}
